import { rgba } from 'polished';
import React, {useEffect, useRef, useState} from 'react';
import {Badge, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import useBasketContext from '../../../../../../contexts/basket/useBasketContext';
import useOperatorContext from '../../../../../../contexts/operator/useOperatorContext';
import useSiteContext from '../../../../../../contexts/site/useSiteContext';
import { Item, Modifier, ModifierGroup } from '../../../../../../contexts/site/site-context';
import ItemUpdateButton from './components/ItemUpdateButton';
import ModifierCheckbox from './components/ModifierCheckbox';
import QuantityButton from './components/QuantityButton';
import classNames from "classnames";
import AllergensModal from './AllergensModal';
import {iLog} from "../../../../../../index";
import {Preferences} from "../../../MenuPage";
import mixpanel from "mixpanel-browser";
import {trackEvent} from "../../../../../../services/api/operator";

type ItemModalProps = {
    show: boolean;
    onHide: () => void;

    originalItem: Item | null;
    edit: boolean;

    allowItemImages: boolean;
    viewOnly?: boolean;
    preferences: Preferences;
    scrollHeader?: boolean
};

// edit: whether we're on the Menu page or the Basket area

const ItemModal = ({
    originalItem,
    show,
    onHide,
    edit,
    allowItemImages,
    viewOnly = false,
    preferences
}: ItemModalProps) => {
    const [item, setItem] = useState<Item | null>(originalItem);
    const [quantity, setQuantity] = useState(item?.quantity ?? 1);
    //const [modifiersTotal, setModifiersTotal] = useState(item?.modifiersTotal ?? 0);
    // const [selectedModifiers, setSelectedModifiers] = useState<Modifier[]>([]);
    const [valid, setValid] = useState(false);
    const [showValidationIssues, setShowValidationIssues] = useState(false);
    const [validationIssues, setValidationIssues] = useState<any>([]);
    const [itemTotal, setItemTotal] = useState(item?.price ?? 0);

    const operatorContext = useOperatorContext();
    const basketContext = useBasketContext();
    const siteContext = useSiteContext();

    const itemContent = useRef<HTMLDivElement>(null);
    const itemImage = useRef<HTMLDivElement>(null);

    const [showAllergens, setShowAllergens] = useState(false);
    const [showHeader, setShowHeader] = useState(false);
    const [showHeaderShadow, setShowHeaderShadow] = useState(false);
    const [showFooterShadow, setShowFooterShadow] = useState(false);
    const [addingItemTransition, setAddingItemTransition] = useState(false);


    // This is solely here to set the defaults and to set the group_uid which
    // we use for efficient mod selections AND to reset on unmount
    useEffect(() => {
        if (!edit && originalItem) {

            originalItem.modifier_groups = originalItem.modifier_groups.map((group) => {
                group.modifiers = group.modifiers.map((mod) => {
                    mod.quantity = mod.is_default ? 1 : 0;
                    mod.group_uid = group.uid;
                    return mod;
                });

                return group;
            });

        }

        setItem(originalItem);
        setQuantity(originalItem?.quantity ?? 1);


        return () => {
            setItem(null);
            setQuantity(1);
            // setSelectedModifiers([]);
            setItemTotal(0);
        };
    }, [ edit, originalItem]);

    // Validate the item has modifiers that match the requirements (maybe do price in here as well???)
    useEffect(() => {
        if (!item) {
            return;
        }

        let validItem = true;
        let validItemIssues: any[] = [];

        //let newItemPrice = originalItem?.price ?? 0;
        // let selectedMods: any[] = [];
        //console.log('original Item', newItemPrice);


        item.modifiersTotal = 0; //reset

        /* If all modifier group min requirements are met then un-disable the button */
        item.modifier_groups.forEach((group) => {
            let groupModsSelected = 0;

            group.modifiers.forEach((mod) => {
                if (mod.quantity > 0) {
                    // selectedMods.push(mod);

                    groupModsSelected += mod.quantity;
                    item.modifiersTotal += mod.price * (mod.quantity ?? 1);
                }
            });

            if (groupModsSelected < group.min) {
                validItem = false;
                validItemIssues.push(group.uid);
            }
        });


        iLog('ItemModal.item', item);

        //item.itemTotal = (item.price + item.modifiersTotal) * (item.quantity ?? 1);
        setItemTotal((item.price + item.modifiersTotal) * quantity);
        // setSelectedModifiers(selectedMods);
        setValid(validItem);
        setValidationIssues(validItemIssues);
        setItem(item);

        return () => {
            setValid(false);
            setValidationIssues([]);
        }
    }, [quantity, item]);


    useEffect(() => {

        function updateScrollPosition() {

            if (itemContent && itemContent.current ) {

                // console.log(itemContent?.current?.scrollTop);
                let offset = 32;
                if (allowItemImages && itemImage && itemImage.current) {
                    offset += itemImage.current.clientHeight
                }
                if ( itemContent?.current?.scrollTop > 32 ) {
                    setShowHeaderShadow(true);
                } else {
                    setShowHeaderShadow(false);
                }

                if(itemContent?.current?.scrollTop > offset) {
                    setShowHeader(true)
                } else {
                    setShowHeader(false)
                }
                if (itemContent?.current?.scrollHeight - itemContent?.current?.scrollTop === itemContent?.current?.clientHeight) {
                    setShowFooterShadow(false)
                } else {
                    setShowFooterShadow(true)
                }
            }

        }

        updateScrollPosition();

        if (itemContent && itemContent.current) {
            itemContent.current.addEventListener("scroll", updateScrollPosition, false);

            document.getElementById('item-modal')?.classList.add('anim-enter');

            return () => {
                setShowHeader(false);
                setShowHeaderShadow(false);
                setShowFooterShadow(false);
                itemContent?.current?.removeEventListener("scroll", updateScrollPosition, false);

                document.getElementById('item-modal')?.classList.remove('anim-enter');
            };
        }


        let div = document.querySelector('.item-modal-dialog > .modal-body');
        if ( div ) {
            let hasVerticalScrollbar = div.scrollHeight > div.clientHeight;
            if ( hasVerticalScrollbar ) {

            }
        }



    }, [itemContent.current, show]);


    // Update price on item when price is calculated
    // useEffect(() => {
    //     if (!item) {
    //         return;
    //     }
    //
    //     //item.price = itemTotal;
    //     setItem(item);
    // }, [quantity, itemTotal]);

    if (!item || item.is_hidden) {
        return null;
    }

    const renderModifierGroups = (groups: ModifierGroup[]) => {
        if (groups.length <= 0) {
            return null;
        }

        return groups.map((group, index) => (
            <ModifierGroupWrapper className="mod-group" id={`group-${group.uid}`} key={`group-${group.uid}-${index}`}>
                <div className="mod-group__header">
                    <p className="mod-group__header__title">
                        <span className={"mod-group__header__title__text"}>{group.name}</span>
                    </p>
                    <p className={`mod-group__header__subtitle ${validationIssues.includes(group.uid) && showValidationIssues ? 'group-is-invalid' : ''}`}>
                        {group.min > 0 ? (
                            <span className="required">
                                {/*{t`Required`} {group.min > 1 ? `(MIN ${group.min})` : ''}*/}
                                {/*<i className={'feather feather-14 feather-alert-triangle'}></i>*/} {t`Required`}
                            </span>
                        ) : null} {group.subtitle}
                    </p>

                    { validationIssues.includes(group.uid) && showValidationIssues && (
                        <p className="mod-group__required-alert">
                            <i className="feather feather-info feather-16 color-filter-invalid" />
                            This choice is required
                        </p>
                    )}

                </div>
                <div className="mod-wrap">
                    {renderModifierItems(group.modifiers, modifierTypeCheckboxOrRadio(group), getMaxLimit(group))}
                </div>
            </ModifierGroupWrapper>
        ));
    };

    const modifierTypeCheckboxOrRadio = (group: ModifierGroup) => {
        return group.multi_choice ? 'checkbox' : 'radio';
    }

    const renderModifierItems = (modifierItems: Modifier[], modifierType: 'checkbox'|'radio', maxReached: boolean) => {
        return modifierItems.map((modifierItem, index) => {
            return (
                <ModifierCheckbox
                    key={`mItem-${modifierItem.uid}-${index}`}
                    originalModifier={modifierItem}
                    type={modifierType}
                    selected={modifierItem.quantity > 0}
                    maxReached={maxReached}
                    updateModifier={updateModifier}
                    preferences={preferences}
                />
            );
        });
    };

    const updateModifier = (modifier: Modifier, quantity: number) => {
        // Map updated mod into selectedItem
        let newItem: Item = { ...item };
        //let found = false;
        let scrollToNext = false;

        iLog('update mod', [modifier.name, quantity]);

        newItem.modifier_groups = newItem.modifier_groups.map((group, group_idx) => {

            if (group.uid !== modifier.group_uid) {
                return group;
            }

            let multi = group.max > 1;

            group.modifiers = group.modifiers.map((mod, idx) => {

                // unselect any previously selected radio
                //if ( !multi && mod.selected ) {
                if ( !multi && quantity > 0 ) {
                    mod.quantity = 0;
                    //mod.selected = false;
                }

                if (mod.uid === modifier.uid) {

                    //if ( mod.quantity === 0 && quantity )

                    mod.quantity = quantity;

                    // scroll to next mod when radio
                    if (!multi && quantity > 0) {
                        scrollToNext = true;
                    }
                }
                return mod;
            });

            return group;
        });


        // console.log('update modifier, match found?', found, newItem);

        // which mod group is next?
        // the one which is required and not completed
        let next = newItem.modifier_groups.find((mg) => {
            let selected_mods = mg.modifiers.filter(m => m.quantity > 0 ).length;

            if ( selected_mods === mg.max ) {
                return false;
            }
            return true;
        });

        if ( next && scrollToNext ) {
            scrollToNextMod(next.uid);
        }

        setItem(newItem);
    };

    const hideItemModal = () => {
        setShowValidationIssues(false);
        onHide();
    };

    const handleItemUpdateButton = () => {
        iLog('adding item to basket & reset', item);
        mixpanel.track('adding item');
        trackEvent('add-item', siteContext.site?.uid ?? '')

        // perform some validation!
        if ( !valid ) {

            setShowValidationIssues(true);

            let uid = validationIssues[0];

            let target = document.querySelector('#group-' + uid) as HTMLElement | null;
            let element = document.querySelector('.modal-body') as HTMLElement | null;


            const yOffset = -70;
            if ( target && element ) {
                element.scrollTo({ top: target.offsetTop + yOffset, behavior: 'smooth' });
            }

            return false;
        }

        setAddingItemTransition(true);

        // reset the selected modifiers array to empty to clean up
        item.modifiers = [];

        basketContext.addItem(item, quantity, edit);

        setTimeout(() => {
            setAddingItemTransition(false);
            hideItemModal();
        }, 500);

    };

    const scrollToNextMod = (uid: string) => {

        let target = document.querySelector('#group-' + uid) as HTMLElement | null;
        let element = document.querySelector('.modal-body') as HTMLElement | null;

        const yOffset = -70;
        if ( target && element ) {
            element.scrollTo({ top: target.offsetTop + yOffset, behavior: 'smooth' });
        }

    };

    const renderTitleBadges = () => {
        let badges = [];
        if (item.is_new) {
            badges.push(
                <Badge key={`tfbg-${item.uid}-new`} className="badge--title badge--title-lg">
                    <div className="badge--title__icon">
                        <i className="feather feather-star color-filter-badge-icon" />
                    </div>
                    <span className={'badge--title__text'}>
                        {t`New`}
                    </span>
                </Badge>,
            );
        }
        if (badges.length > 0) {
            return (
                <div className="badges-title">
                    {badges.map((badge) => badge)}
                </div>
            )
        } else {
            return null
        }
    };


    const renderDietBadges = () => {
        let badges = [];
        if (item.is_vegan) {
            badges.push(
                <OverlayTrigger
                    key={'veganPlacement'}
                    placement={'top'}
                    overlay={
                        <Tooltip id={`tooltip-veganPlacement`}>
                            Suitable for Vegans
                        </Tooltip>
                    }
                >
                    <Badge className="badge-vg badge--item-modal" key={`tfbg-${item.uid}-vegan`} title="Vegan">
                        VG
                    </Badge>
                </OverlayTrigger>

            );
        }

        /*if (item.is_halal) {
            badges.push(
                <OverlayTrigger
                    key={'halalPlacement'}
                    placement={'top'}
                    overlay={
                        <Tooltip id={`tooltip-halalPlacement`}>
                            Halal
                        </Tooltip>
                    }
                >
                    <Badge className="badge-h badge--item-modal" key={`tfbg-${item.uid}-halal`} title="Halal">
                        H
                    </Badge>
                </OverlayTrigger>
            );
        }*/

        if (item.is_vegetarian) {
            badges.push(
                <OverlayTrigger
                    key={'vegetarianPlacement'}
                    placement={'top'}
                    overlay={
                        <Tooltip id={`tooltip-vegetarianPlacement1`}>
                            Suitable for Vegetarians
                        </Tooltip>
                    }
                >
                    <Badge className="badge-v badge--item-modal"
                           key={`tfbg-${item.uid}-vegetarian`}
                           title="Vegetarian">
                        V
                    </Badge>
                </OverlayTrigger>
            );
        }

        if (item.is_gluten_free) {
            badges.push(
                <OverlayTrigger
                    key={'gfPlacement'}
                    placement={'top'}
                    overlay={
                        <Tooltip id={`tooltip-gfPlacement`}>
                            Gluten Free
                        </Tooltip>
                    }
                >
                    <Badge className="badge-gf badge--item-modal" key={`tfbg-${item.uid}-gf`} title="Gluten Free">
                        GF
                    </Badge>
                </OverlayTrigger>
            );
        }

        // if (item.allergens) {
        //     badges.push(<Badge key={`tfbg-${item.uid}-allergens`} variant="secondary">Allergens</Badge>)
        // }

        if (badges.length > 0) {
            return <>{badges.map((badge) => badge)}</>;
        }
        return null;
    };

    const headerClassNames = classNames({
        'modal-show-header': showHeader,
        'modal-show-header-shadow': showHeaderShadow,
    });
    const footerClassNames = classNames({
        // 'modal-show-footer-shadow': showFooterShadow,
        'modal-show-footer-shadow': true,
    });

    const renderItemTimeRestriction = () => {
        if ( !operatorContext.operator?.has_timed_categories ) {
            return null;
        }

        return (
            <p className={'item__details__timings'}>
                <i className={'feather feather-clock feather-16'}></i>
                <span>Available Monday - Friday, 12-4pm</span>
            </p>
        );
    };

    return (
        <ItemModalWrapper
            show={show}
            onHide={onHide}
            size="lg"
            backdrop={true}
            backdropClassName="modal-item-backdrop"
            className="item-modal-dialog"
            centered
            animation={false}
            id={'item-modal'}
            image={allowItemImages && item.image}
        >
            <Modal.Header className={headerClassNames}>
                <h4 className='modal__title'>{item.name}</h4>
                <div className="modal-close__wrapper">
                    <button role="button" className={'modal-close__btn'} onClick={() => hideItemModal()}>
                        <i className="feather feather-x feather-18 brand-color-filter" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body ref={itemContent}>
                {allowItemImages && item.image ? (
                    <ItemImage ref={itemImage} image={item.image} />
                ) : null}
                <div className="item__details">
                    <h4 className={"item__details__title" + (!item.image ? ' item__details__title--padding' : '')}>
                        {item.name}
                    </h4>
                    {renderItemTimeRestriction()}

                    { item.description && (
                        <span className="item__details__description">{item.description}</span>
                    )}

                    {renderTitleBadges()}

                    { renderDietBadges() || (preferences.calories && item.calories && item.calories.length > 0) ? (
                        <div className="item__details__calories">
                            { preferences.calories && item.calories && item.calories.length > 0 ? (
                                <span>{item.calories} kcal</span>
                            ) : null }
                            <span className="item__details__badges-diet">{renderDietBadges()}</span>
                        </div>
                    ) : null }


                    <div className="item__allergens">
                       {/* {item.allergens &&  <Allergens>
                        {item.allergens.split(',').map((item) => (
                            <span key={`allergen-` + item}>{item}</span>
                        ))}
                        </Allergens>}*/}
                        <AllergensModal
                            show={showAllergens}
                            onHide={() => setShowAllergens(false)}>
                            <div>
                                {item.allergens && (
                                    <div className="allergens-modal-details">{ item.allergens }</div>
                                )}
                                <div className="allergens-modal-general-info" dangerouslySetInnerHTML={{ __html: operatorContext.operator?.allergen_info ?? '' }}></div>
                            </div>
                        </AllergensModal>
                        <button className={"item__allergens__btn"} onClick={() => setShowAllergens(true)}><i className={'feather feather-16 feather-info'}></i> Allergens</button>
                    </div>
                </div>
                <div className="item__modifiers">
                    {renderModifierGroups(item.modifier_groups)}
                </div>
            </Modal.Body>
            <Modal.Footer className={footerClassNames}>

                <div className="footerContent">
                    <QuantityButton minimum={true} quantity={quantity} setQuantity={setQuantity} type={'large'} />
                    <ItemUpdateButton
                        validated={valid}
                        disabled={viewOnly}
                        edit={edit}
                        total={itemTotal}
                        loading={addingItemTransition}
                        onClick={handleItemUpdateButton}
                    />
                    { viewOnly && (
                        <LocationClosed>
                            <p>This location is not currently accepting orders. Please check back later to place an order or <a href="/">choose another location</a>.</p>
                        </LocationClosed>
                    )}
                </div>
            </Modal.Footer>
        </ItemModalWrapper>
    );
};

const LocationClosed = styled.div`
    width: 100%;
    position: absolute;
    background-color: var(--border-grey);
    p {
        width: 100%;
        padding: 20px;
        color: var(--text-black);
        font-weight: 400;
        margin: 0;
        a {
            text-decoration: underline;
            font-weight: 500;
            color: var(--text-black);
        }
    }
`;


const getMaxLimit = (group: ModifierGroup) => {
    let maxLimitReached = false;
    let checked = 0;
    group.modifiers.forEach((mod) => {
        if (mod.quantity > 0) {
            checked = checked + (mod.quantity ?? 1);
            if (checked >= group.max) {
                maxLimitReached = true;
            }
        }
    });

    return maxLimitReached;
};

const ItemModalWrapper = styled(Modal)<{image: boolean}>`
    .modal-body {
        overflow-y: auto;
    }
    .modal-header {
        display: flex;
        align-items: center;
        min-height: 64px;
        > div { width: 100%}
        background-color: transparent;
        z-index: 1;
        box-shadow: none;
        border: 0;
        width: 64px;
        padding: 16px 20px 16px 20px;
        ${(props) => props.image ? `
            position: absolute;
            top: 0;
            right: 0;` : ''}

        .modal__title {
            margin-bottom: 0;
            transition: opacity 0.2s ease-in-out;
            opacity: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 40px;
        }
        &.modal-show-header {
            background-color: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
            left: 0;
            width: 100%;
            .modal__title {
                opacity: 1;
            }
        }

        &.modal-show-header-shadow {
            /*box-shadow: 0 4px 20px rgba(0,0,0,0.12);*/
        }
    }

    .modal-body {
        padding: 0;
        padding-bottom: 10px;
    }

    .item {
        &__details {
            padding: 20px;
            ${(props) => props.image ? '' : 'padding-top: 0;'}
            &__title {
                margin-bottom: 8px;
                &--padding {
                    padding-right: 40px;
                }
            }
            &__timings {
                margin-top: 8px;
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                gap: 6px;
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                letter-spacing: 0;
                color: var(--brand);
                @media screen and (min-width: 768px) {
                    // font-size: 16px;
                    // line-height: 24px;
                    // margin-top: 0;
                    font-weight: 500;
                }
            }
            &__description {
                color: var(--text-grey);
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 8px;
                font-weight: 400;
                display: inline-block;
            }
            &__badges-diet {
                display: flex;
                gap: 8px;
                align-items: center;
            }
            &__calories {
                font-size: 14px;
                line-height: 19px;
                margin-bottom: 8px;
                color: var(--text-grey);
                display: flex;
                align-items: start;
                gap: 8px;
                flex-shrink: 0;
                .bullet {
                    width: 4px;
                    height: 4px;
                    background-color: var(--text-grey);
                    border-radius: 50%;
                    display: inline-block;
                    margin: 0 5px;
                }

                i {
                    margin-left: 8px;
                    height: 12px;
                    width: 12px;
                    margin-top: 2px;
                }
            }

        }


        &__modifiers {
            .mod-group {
                padding: 0 20px 24px 20px;
                > p {
                    padding: 0px 0px;
                }
                .mod-wrap > div {
                    display: flex;
                    align-items: center;

                    label {
                        padding-left: 0;
                        min-width: 0;
                    }

                    &:first-child { border-top: none !important; }
                }

                .group-is-invalid { margin-bottom: 0px; }

                .mod-group__required-alert {
                    display: flex;
                    align-items:center;
                    background-color: transparent;
                    font-weight: 500;
                    border: none;
                    color: var(--invalid);
                    padding: 4px 0;
                    margin: 0;

                    i { margin-right: 8px; }
                }
            }
        }

        &__allergens {
            line-height: 0;
            &__btn {
                font-size: 16px;
                line-height: 22px;
                background-color: transparent;
                border: 0;
                font-weight: 500;
                color: var(--brand);
                padding: 0;
                position: relative;
                display: inline-flex;
                align-items: center;
                gap: 4px;
                ::after {
                    position: absolute;
                    content: '';
                    left: 0;
                    right: 0;
                    bottom: -2px;
                    border-bottom: 1px solid transparent;
                    transition: border-color 0.15s ease-in-out;
                }
                :hover {
                    ::after {
                        border-color: var(--brand);
                    }
                }
            }
        }
    }

    .modal-footer {
        padding: 16px 20px !important;
        transition: box-shadow 0.15s ease-in-out;

        .footerContent {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            gap: 20px;
        }
    }
    
    // Title Badges row

    .badges-title {
        margin-bottom: 8px;
        line-height: 0;
    }

`;

const ModifierGroupWrapper = styled.div`
    font-size: 14px;
    .mod-group__header {
        &__title {
            font-size: 16px;
            line-height: 22px;
            color: var(--text-grey);
            margin-bottom: 0px;
            font-weight: 500;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            &__text {
                width: 100%;
                display: inline-block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: var(--text-black);
            }

            @media screen and (max-width: 575px) {
                font-size: var(--core_text_ui_lg);
                font-family: ${(props) => props.theme.bodyFont.name};
                color: var(--text-black);
                text-transform: none;
            }
        }
        &__subtitle {
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            margin-bottom: 0;
            color: var(--text-grey);
            .required {
                color: var(--invalid);
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
            }
        }

    }
`;

const ItemImage = styled.div<{ image: string }>`
    width: 100%;
    height: calc(100vw * 0.5625); // ratio 16:9
    background: no-repeat center center;
    background-size: cover;
    background-image: url('${(props) => props.image}');
    background-position: center;
    /* background-size: 140%; */
    background-size: cover;

    @media screen and (min-width: 768px) {
        height: calc(560px * 0.5625);
    }
`;

const Allergens = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    margin-top: 12px;

    span {
        background: #E7E7E7;
        padding: 0 6px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 500;
    }
`;

const Separator = styled.div`
    margin: 20px -24px;
    height: 20px;
    border-top: 8px solid #f7f7f7;
    @media screen and (max-width: 575px) {
        margin: 24px -16px;
    }
`;

export default ItemModal;
