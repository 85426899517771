import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { t } from 'ttag';
import vocab from '../../../config/channels';
import { operatorName } from '../../../config/operator';
import { TChannel } from '../../../contexts/operator/operator-context';
import useOperatorContext from '../../../contexts/operator/useOperatorContext';
import { TSite } from '../../../contexts/site/site-context';
import useSiteContext from '../../../contexts/site/useSiteContext';
import PageSpinnerThree from '../../general/PageSpinnerThree';
import AppLayout from '../../layouts/app/AppLayout';
import ChannelSelector from './components/ChannelSelector';
import DeliveryTab from './components/DeliveryTab';
import EatInTab from "./components/EatInTab";
import SiteFilter from "./components/SiteFilter";
import useBasketContext from "../../../contexts/basket/useBasketContext";
import {iLog} from "../../../index";
import mixpanel from 'mixpanel-browser';
import powered_by from "../../../assets/images/logo-powered-by.svg";

type ChannelSelectPageProps = {
    channel?: TChannel | null;
};

const ChannelSelect = ({ channel }: ChannelSelectPageProps) => {
    const operatorContext = useOperatorContext();
    const siteContext = useSiteContext();
    const basketContext = useBasketContext();
    const navigate = useNavigate();

    const [enabledChannels, setEnabledChannels] = useState<TChannel[]>([]);
    const [activeChannelTab, setActiveChannelTab] = useState('');

    useEffect(() => {

        basketContext.clearBasket();
        localStorage.removeItem('postcode');
        localStorage.removeItem('table');

        if (siteContext) {
            let flow = siteContext.flow === '' ? '' : (operatorContext.operator?.flow ?? 'noflow') ;
            siteContext.fetchSites(operatorName, flow);
        } else {
            console.warn('Skipped fetch site call.');
        }
    }, []);

    useEffect(() => {
        if ( siteContext.sites?.length === 1 ) {

            let singleSite = siteContext.sites[0];
            let enabledChannels = Object.keys(singleSite.channels);

            if ( enabledChannels.length === 1 ) {

                if (enabledChannels[0] === 'dine_in') {
                    navigate(`/${vocab.dineIn.handle}/${singleSite.handle}/table`)
                } else if (enabledChannels[0] === 'delivery') {
                    // nothing!
                } else {
                    navigate(`/${enabledChannels[0]}/${singleSite.handle}`)
                }

            }
        }
    }, [siteContext.sites]);

    useEffect(() => {

        if (operatorContext.operator?.channels.collection) {
            setEnabledChannels(enabledChannels => [...enabledChannels, vocab.collection.name]);
        }

        if (operatorContext.operator?.channels.delivery) {
            setEnabledChannels(enabledChannels => [...enabledChannels, vocab.delivery.name]);
        }

        if (operatorContext.operator?.channels.dine_in) {
            setEnabledChannels(enabledChannels => [...enabledChannels, vocab.dineIn.name]);
        }

    }, []);

    useEffect(() => {
        setActiveChannelTab(channel ?? enabledChannels[0]);
    }, [channel, enabledChannels])


    if (!siteContext || !siteContext.sites) {
        return <PageSpinnerThree show={true} />;
    }

    const collectionSites = siteContext.sites?.filter((site) => site.channels.collection);
    const dineInSites = siteContext.sites?.filter((site) => site.channels.dine_in);
    //const deliverySites = siteContext.sites?.filter((site) => site.channels.delivery);

    const renderChannel = () => {
        switch (activeChannelTab) {
            case vocab.collection.name:
                return (
                    <>
                        <SiteFilter key="collection_filter" sites={collectionSites as TSite[]} channel={vocab.collection} />
                    </>
                );

            case vocab.dineIn.name:
                return (
                    <>
                        <SiteFilter key="dine_in_filter" sites={dineInSites as TSite[]} channel={vocab.dine_in} />
                    </>
                );

            case vocab.delivery.name:
                return (
                    <>
                        <DeliveryTab />
                    </>
                );

            default:
                return <p>{t`No channel selected.`}</p>;
        }
    };

    return (
        <AppLayout page="channels">
            <Helmet>
                <title>
                    {operatorContext.operator?.name ?? ''} {t`Online Ordering - Powered by orderswift`}
                </title>
                <link rel="icon" href={operatorContext.operator?.theme?.favicon_url} />
            </Helmet>


            <ChannelSelector
                selectedChannel={activeChannelTab}
                enabledChannels={enabledChannels}
                setActiveChannelTab={setActiveChannelTab}
            >
                <ChannelContent>
                    <span className='d-md-none mobile_bg' />
                    <div className='channelWrapper'>{renderChannel()}</div>
                    <div className='orderswift-banner-channel'>
                        <img width={188} height={28} src={powered_by} alt={'Powered by Orderswift'} />
                    </div>
                </ChannelContent>
            </ChannelSelector>

            <Outlet />
        </AppLayout>
    );
};

const ChannelContent = styled.div`
    flex-grow: 1;
    margin-top: 24px; 
    .orderswift-banner-channel {
        z-index: 999;
        display: flex;
        justify-content: center;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        box-shadow: 0 -1px 0 var(--border-grey);
        padding: 2px 0;
        @media screen and (min-width: 720px) {
            width: 50%;
            min-width: 720px;
            right: auto;
        }
        
    }
`;

export default ChannelSelect;
