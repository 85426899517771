import React, { useState } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';
import { money } from '../../../../../../../services/format';
import { CTA } from '../../../../../../general/Button';
import PageSpinnerThree from '../../../../../../general/PageSpinnerThree';
import useOperatorContext from "../../../../../../../contexts/operator/useOperatorContext";

type AddButtonProps = {
    validated: boolean;
    edit: boolean;
    onClick: () => void;
    total: number;
    disabled?: boolean;
    loading: boolean;
};

const ItemUpdateButton = ({ validated, edit, onClick, total, disabled = false, loading }: AddButtonProps) => {

    const operatorContext = useOperatorContext();

    const renderButtonContent = () => {

        return (
            <>
                <span className="desktop">{edit ? t`Edit item` : t`Add to order`}</span>
                <span className="mobile">{edit ? t`Edit` : t`Add`}</span>
                <span className="menu-item-btn-money" data-testid="item-sum">{money(total, operatorContext.operator?.ccy, operatorContext.operator?.locale)}</span>
                {/* {money(basketContext.price.total)} */}
            </>
        );
    };

    return (
        <AddButtonWrapper onClick={onClick} dual jumbo>
            {renderButtonContent()}
            <StyledSpinnerOverlay show={loading}>
                <PageSpinnerThree show={true} color={'#FFFFFF'}/>
            </StyledSpinnerOverlay>
        </AddButtonWrapper>
    );
};

const StyledSpinnerOverlay = styled.div<{show: boolean}>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #EBEBEB;
    transition: opacity 0.1s ease-in-out;
    opacity: ${(props) => props.show ? '1' : '0'};
    border-radius: 6px;
`;

const AddButtonWrapper = styled(CTA)`
    width: 100%;
    height: 48px;
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
    
    @media screen and (min-width: 540px) {

        .desktop {
            display: block;
        }

        .mobile {
            display: none;
        }

        span:first-of-type {
            display: inline-block;
        }
    }

`;

export default ItemUpdateButton;
