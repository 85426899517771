import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import styled, {keyframes} from 'styled-components';
import { t } from 'ttag';
import useOrderContext from '../../../../../contexts/order/useOrderContext';
import {rgba} from "polished";
import {Theme} from "../../../../../contexts/operator/operator-context";
import {money} from "../../../../../services/format";
import useOperatorContext from "../../../../../contexts/operator/useOperatorContext";
interface TipCheckboxProps {
    id: string;
    label?: string;
    tipValue: number;
    disabled: boolean;
    defaultChecked?: boolean;
    selected: boolean;
    isConfirmationPage: boolean;
}

const ServiceCheckbox = ({
    id,
    label = t`Service Fee`,
    tipValue,
    disabled = false,
    defaultChecked = false,
    selected,
    isConfirmationPage = false
}: TipCheckboxProps) => {

    const orderContext = useOrderContext();
    const operatorContext = useOperatorContext();

    useEffect(() => {
        orderContext.setServiceSelected(defaultChecked);
    }, [defaultChecked]);

    const handleCheckboxClick = () => {
        orderContext.setServiceSelected(!selected);
    }

    return (
        <Form.Group controlId={id} className={'mb-0'}>
            <Form.Label className={`d-block mb-0`}>
                <Layout cursorNormal={ isConfirmationPage || tipValue === 0 || disabled }>
                    { !disabled && (<StyledCheckbox>
                        <Form.Check type={'checkbox'} id={id} onClick={handleCheckboxClick} disabled={disabled} />
                        <CheckboxIcon
                            selected={selected}
                            className={selected ? 'anim--modifier-pulse' : ''}
                        >
                            {selected && (
                                <svg
                                    className={'text-white'}
                                    width={14}
                                    height={14}
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M2.293 7.707l3.774 3.774 5.702-6.841-1.537-1.28-4.299 5.159-2.226-2.226-1.414 1.414z"
                                        fill="currentColor"
                                    />
                                </svg> )}
                        </CheckboxIcon>
                    </StyledCheckbox>)}
                    <span className="checkbox-label">{label}</span>
                    <span className="checkbox-price">{money(tipValue, operatorContext.operator?.ccy, operatorContext.operator?.locale)}</span>
                </Layout>
            </Form.Label>
        </Form.Group>
    );
};

const Layout = styled.div<{ cursorNormal: boolean }>`
    display: flex;
    align-items: center;
    cursor: ${(props) => props.cursorNormal ? 'normal' : 'pointer' };
    .checkbox-label {
        user-select: none;
        flex: 1;
        font-size: 16px;
    }

    .checkbox-label,
    .checkbox-price {
        font-size: 16px;
        color: var(--text-black);
        font-weight: 500;
       
        @media screen and (max-width: 576px) {
            font-size: 14px;
        }
    }
`;

const StyledCheckbox = styled.span`
    display: inline-flex;
    padding-right: 10px;
    .form-check {
        display: none;
    }
    span {
        width: 20px;
        height: 20px;
    }
`;

const CheckboxIcon = styled.div<{selected: boolean}>`
    line-height: 0;
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 2px solid transparent;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    &.anim--modifier-pulse {
        animation: ${(props) => modifierPulse(props.theme)} 1s;
        box-shadow: 0 0 0 0 ${(props) => rgba(props.theme.v3.ui.other.link, 1)};
    }
    ${(props) => props.selected ? `
        background-color: ${props.theme.v3.ui.other.link};
    ` : `
     border-color: ${props.theme.v3.ui.other.link};`}
`;

const modifierPulse = (theme:Theme) => keyframes`
    0% { box-shadow: 0 0 0 0 ${rgba(theme.v3.ui.other.link, 0.7)}; }
    70% { box-shadow: 0 0 0 6px ${rgba(theme.v3.ui.other.link, 0)}; }
    100% { box-shadow: 0 0 0 0 ${rgba(theme.v3.ui.other.link, 0)}; }
`;
/*
const StyledCheckbox = styled.span`
    display: inline-flex;
    padding-right: 16px;
    .form-check {
        display: none;
        font-size: 16px;
    }
    span {
        width: 24px;
        height: 24px;
        font-size: 24px;

        @media screen and (max-width: 576px) {
            height: 18px;
            width: 18px;
            font-size: 18px !important;
        }
    }
`;*/

export default ServiceCheckbox;
