import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import styled, {keyframes} from 'styled-components';
import { Modifier } from '../../../../../../../contexts/site/site-context';
import { money } from '../../../../../../../services/format';
import ModifierCheckboxQuantity from './ModifierCheckboxQuantity';
import {Theme} from "../../../../../../../contexts/operator/operator-context";
import {rgba} from "polished";
import {Preferences} from "../../../../MenuPage";
import useOperatorContext from "../../../../../../../contexts/operator/useOperatorContext";

type ModifierCheckboxProps = {
    originalModifier: Modifier;
    type: 'checkbox' | 'radio';
    selected: boolean;
    maxReached: boolean;
    //updateModifier: (m: Modifier, multi: boolean) => void;
    updateModifier: (m: Modifier, quantity: number) => void;
    preferences: Preferences;
};

const ModifierCheckbox = ({ originalModifier, type, selected, maxReached, updateModifier, preferences }: ModifierCheckboxProps) => {

    const operatorContext = useOperatorContext();

    //const [quantity, setQuantity] = useState<number|null>();
    const [quantity, setQuantity] = useState<number>(originalModifier.quantity);
    const [showAnim, setShowAnim] = useState(false);
    useEffect(() => {
        setShowAnim(false);
        if (selected) {
            setTimeout(() => {
                setShowAnim(true);
            }, 500)
        }
    }, [selected])

    useEffect(() => {
        if ( type === 'radio' ) { return; }
        if ( quantity === null ) { return; }
        updateModifier(originalModifier, quantity ?? 0);

    }, [ quantity ]);

    useEffect( () => {
        setQuantity(originalModifier.quantity);
    }, [ originalModifier ]);

    /*useEffect(() => {
        if (quantity && selected && quantity !== modifier.quantity) {
            if (!selected && maxReached && type === 'checkbox') {
                return;
            }
            if (selected && type === 'radio') {
                return;
            }

            let updatedModifier = { ...modifier };
            updatedModifier.quantity = quantity;

            // selectModifier(modifier.uid);
            updateModifier(updatedModifier, type === 'checkbox');
        }

        if ( quantity === 0 ) {

            let updatedModifier = { ...modifier };
            updatedModifier.selected = false;
            updatedModifier.quantity = quantity;
            updateModifier(updatedModifier, type === 'checkbox');

        }



    }, [quantity]);*/

    /*const handleCheckboxClick = () => {
        if (!selected && maxReached && type === 'checkbox') {
            return;
        }
        if (selected && type === 'radio') {
            return;
        }
        let updatedModifier = { ...modifier };
        let status = !selected;
        updatedModifier.selected = status;
        updatedModifier.quantity = status ? (type === 'checkbox' ? quantity : 1) : 0;

        // selectModifier(modifier.uid);
        updateModifier(updatedModifier, type === 'checkbox');
    };*/

    const handleCheckboxClick = () => {
        if ( type === 'radio' ) {

            updateModifier(originalModifier, 1);

        } else if ( type === 'checkbox' ) {

            if ( quantity === 0 && !maxReached ) {
                setQuantity(1);
            } else {
                setQuantity(0);
            }

            updateModifier(originalModifier, quantity);

        }
    };

    const renderCheckbox = () => {
        if (type === 'checkbox') {
            return (
                <CheckboxLayout>
                    <StyledCheckbox>
                        <Form.Check type={type} id={`mod-${type}-${originalModifier.uid}`} onClick={handleCheckboxClick} />
                        <CheckboxIcon
                            selected={selected}
                            className={selected ? 'anim--modifier-pulse' : ''}
                            >
                            {selected ?
                                <svg
                                    className={'text-white'}
                                    width={14}
                                    height={14}
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M2.293 7.707l3.774 3.774 5.702-6.841-1.537-1.28-4.299 5.159-2.226-2.226-1.414 1.414z"
                                        fill="currentColor"
                                    />
                                </svg>
                            : null}
                        </CheckboxIcon>
                    </StyledCheckbox>
                </CheckboxLayout>
            );
        } else {
            return (
                <RadioLayout>
                    <StyledRadio>
                        <Form.Check type={type} id={`mod-${type}-${originalModifier.uid}`} onClick={handleCheckboxClick} />
                        <RadioIcon selected={selected} className={selected ? 'anim--modifier-pulse' : ''}>
                            <div/>
                        </RadioIcon>
                    </StyledRadio>
                </RadioLayout>
            );
        }
    };

    if (originalModifier.is_hidden) {
        return null;
    }

    return (
        <Layout>
            <StyledButton
                modifier={originalModifier}
                aria-label={'Item, Price'}
                tabIndex={0}
            >
                <label>
                    {renderCheckbox()}
                    <div className={'mod-details'}>
                        <span className="mod-name">
                            {originalModifier.name}
                        </span>
                        { preferences.calories && originalModifier.calories && originalModifier.calories.length > 0 ? (
                            <span className="mod-description">
                                {originalModifier.calories} kcal
                            </span>
                        ) : null }
                    </div>

                    <span className="mod-price">
                        {originalModifier.price ? ' +' + money(originalModifier.price, operatorContext.operator?.ccy, operatorContext.operator?.locale) : ''}
                    </span>
                </label>
                {selected && type === 'checkbox' ? (
                    <ModifierCheckboxQuantity
                        setQuantity={setQuantity}
                        quantity={quantity ?? 1}
                        maxReached={maxReached}
                    />
                ) : null }
            </StyledButton>
        </Layout>
    );
};

const RadioIcon = styled.div<{selected: boolean}>`
    line-height: 0;
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    border: 2px solid ${props => props.theme.v3.ui.other.link};
    padding: 3px;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    > div {
        height: 8px;
        width: 8px;
        border-radius: 8px;
        background-color: ${props => props.selected ? props.theme.v3.ui.other.link : ''};
        transition: background-color 0.15s ease-in-out;
    }
`

const CheckboxIcon = styled.div<{selected: boolean}>`
    line-height: 0;
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 2px solid transparent;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    ${(props) => props.selected ? `
        background-color: ${props.theme.v3.ui.other.link};
    ` : `
     border-color: ${props.theme.v3.ui.other.link};`}
`

const CheckboxLayout = styled.div`
    line-height: 0;
`;

const StyledCheckbox = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    .form-check {
        display: none;
    }
    width: 24px;
    height: 24px;
`;

const RadioLayout = styled.div`
    display: flex;
`;

const StyledRadio = styled.span`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    .form-check {
        display: none;
    }
    span {
        width: 24px;
        height: 24px;
    }
`;

const StyledButton = styled.div<{ modifier: Modifier }>`
    color: ${(props) => props.theme.color_primary};
    background-color: ${(props) => props.theme.color_bg};
    border: 0;
    text-align: left;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 6px;
    padding: 0;
    &:focus {
        outline: none;
        box-shadow: none;
    }

    > label {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        padding: 0px;
        margin-bottom: 0;
        width: 100%;
        .mod-details {
            flex-grow: 1;
            overflow: hidden;
        }
        .mod-name {
            user-select: none;
            line-height: 20px;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: var(--text-black);
            white-space: nowrap;
            text-overflow: ellipsis;
            min-width: 0px;
            overflow: hidden;
            display:block;
        }

        .mod-description {
            user-select: none;
            font-size: 14px;
            line-height: 19px;
            font-weight: 400;
            color: var(--text-grey);
            white-space: nowrap;
            text-overflow: ellipsis;
            min-width: 0px;
            display:block;
        }
        .mod-price {
            user-select: none;
            line-height: 20px;
            font-size: 16px;
            line-height: 22px;
            color: var(--text-grey);
            font-weight: 400;
            min-width: 60px;
            text-align: right;
            white-space: nowrap;
            flex: 1 0 auto;
            display: inline-block;
        }
       

    }
`;
const Layout = styled.div`
    padding-top: 16px;
`;

export default ModifierCheckbox;
