import { PaymentMethod, Stripe } from '@stripe/stripe-js';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import config from '../../config/channels';
import { useStateRef } from '../../hooks/usestateref';
import { APIErrorCode } from '../../services/api';
import {postCustomer, postOrder} from '../../services/api/basket';
import useBasketContext from '../basket/useBasketContext';
import { CustomerFormObject } from '../customer/customer-context';
import useCustomerContext from '../customer/useCustomerContext';
import useSiteContext from '../site/useSiteContext';
import {OrderContext, OrderContextData, OrderData, TError, TOrder} from './order-context';
import {iLog} from "../../index";
import {toastError} from "../../hooks/toastError";
import {t} from "ttag";
import {useLocalStorage} from "../../hooks/localstorage";
import mixpanel from "mixpanel-browser";

const OrderContextProvider = ({ children }: { children: JSX.Element }) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    //const [timeslot, setTimeslot, timeslotRef] = useStateRef<Timeslot | null>(null);
    const [serviceSelected, setServiceSelected, serviceSelectedRef] = useStateRef(false);
    const [gratuitySelected, setGratuitySelected, gratuitySelectedRef] = useStateRef(false);
    const [gratuityVal, setGratuityVal] = useState(0);
    const [deliveryInstructions, setDeliveryInstructions] = useState('');
    const [total, setTotal] = useState(0);

    const [order, setOrder] = useState<TOrder | null>(null);
    const [storedOrder, setStoredOrder] = useLocalStorage('order', null);

    const [orderData, setOrderData, orderDataRef] = useStateRef<OrderData>({
        customer: {
            email: '',
            first_name: '',
            last_name: '',
            tel: '',
            address_1: '',
            address_city: '',
            address_postcode: '',
            address_country: 'GB',
            address_notes: '',
            billing_postcode: '',
            opt_in_marketing: false,
            allow_orderswift_emails: false,
        },
        site_id: '',
        channel: 'collection',
        basket_id: '',
        time: '',
        asap: false,
        gratuity: 0
    });

    const customerContext = useCustomerContext();
    const basketContext = useBasketContext();
    const siteContext = useSiteContext();


    // Clear basket if on the homepage..
    useEffect(() => {
        if ( location.pathname === '/' || location.pathname.split('/').includes('collection') ) {
            setStoredOrder(null);
        }
    }, []);


    useEffect(() => {
        iLog('set order data', basketContext);
        setOrderData({
            ...orderData,
            basket_id: basketContext.basket?.basket_id ?? '',
            time: basketContext.basket?.ready_time?.id ?? '',
            asap: basketContext.basket?.ready_time?.is_asap ? true : false,
            payment_intent: { payment_intent: basketContext.basket?.payment_intent?.client_secret ?? '' },
            site_id: basketContext.validatedSite?.uid ?? '',
        });
    }, [basketContext.basket]);

    useEffect(() => {
        // does this need to be taken from validated_site
        setOrderData({
            ...orderData,
            channel: siteContext.channel ?? 'collection',
        });
    }, [siteContext.site]);

    // Validated price with/without service
    useEffect(() => {
        let newValidatedTotal = basketContext.validatedBasket?.total ?? 0;

        if (
            (serviceSelected && basketContext.validatedBasket?.service_optional)
            ||
            (!basketContext.validatedBasket?.service_optional &&
                basketContext.validatedBasket?.service_default)
        ) {
            newValidatedTotal += basketContext.validatedBasket?.service_fee ?? 0;
        }

        setTotal(newValidatedTotal);

        return () => setTotal(0);
    }, [ basketContext.validatedBasket, serviceSelected ]);

    // Get data ready for sending off to the
    const getOrderData = (customerFormData: CustomerFormObject, paymentMethod?: PaymentMethod) => {
        let checkoutData: OrderData = { ...orderDataRef.current };

        iLog('getting order data, with customer', {
            a: checkoutData.customer,
            b: orderData.customer,
            c: customerContext.customer,
            d: customerContext.customerFormData,
            e: customerFormData,
            f: orderData,
            g: basketContext
        });

        // why is there no site id on this??

        //console.debug('customerFormData', customerFormData);

        // if (siteContext.channel !== config.dineIn.name && !timeslotRef.current?.id) {
        //     throw new Error('No timeslot selected');
        // }

        checkoutData.flow = basketContext.basket?.flow ? basketContext.basket?.flow : 'NOFLOW';
        checkoutData.is_secure_payment = false;

        // 2023-03-21: changing this to ValidatedBasket
        // checkoutData.time = basketContext.timeslot?.id ?? '';
        // checkoutData.asap = basketContext.timeslot?.is_asap ?? false;

        checkoutData.time = basketContext.validatedBasket?.ready_time.id ?? '';
        checkoutData.asap = basketContext.validatedBasket?.ready_time.is_asap ?? false;


        // timeslot not updating, if you don't touch anything but the paynow and the timeslot - something to do with the component not refreshing
        //console.log('timeslots in order context', timeslotRef.current, siteContext.timeslots);

        if ( siteContext.channel === config.dineIn.name ) {
            checkoutData.time = moment().format('YYYY-MM-DD HH:mm');
            checkoutData.asap = true;
        }


        // checkoutData.time =
        //     siteContext.channel === config.dineIn.name
        //         ? moment().format('YYYY-MM-DD HH:mm')
        //         : timeslotRef.current?.id ?? ''; ///Y-m-d H:i

        // checkoutData.asap =
        //     siteContext.channel === config.dineIn.name
        //         ? true
        //         : timeslotRef.current?.is_asap ?? false; // TODO convert to customerForm

        // should this just be customerContext?????????
        checkoutData.customer = JSON.stringify({
            email: customerFormData.email,
            first_name: customerFormData.firstName,
            last_name: customerFormData.lastName,
            cardholderName: customerFormData.cardholderName,
            tel: customerFormData.tel,
            address_1: customerFormData.address1 ?? '',
            address_2: customerFormData.address2 ?? '',
            address_city: customerFormData.city ?? '',
            address_postcode: customerFormData.postcode ?? '',

            address_country: 'GB',
            address_notes: deliveryInstructions,
            billing_postcode: '',
            allow_operator_emails: customerFormData.opt_in_marketing,
            allow_orderswift_emails: false,
        }) as any;

        checkoutData.payment_intent = JSON.stringify({
            payment_intent: basketContext.basket?.payment_intent?.client_secret,
        }) as any;

        if (paymentMethod)
            checkoutData.payment_method = JSON.stringify({ id: paymentMethod.id, card: paymentMethod.card }) as any;
        if (basketContext.specialNotes && basketContext.specialNotes.length > 0)
            checkoutData.special_notes = basketContext.specialNotes;
        if (basketContext.tableNumber && siteContext.channel === config.dineIn.name)
            checkoutData.table_number = basketContext.tableNumber; // TODO put this in customerFormData?
        // if (order.cover_count !== "") checkoutData.cover_count = order.cover_count;
        if ( (basketContext.basket?.promo_discount ?? 0) > 0) checkoutData.promo_code = basketContext.basket?.codes[0].reference;
        //if (serviceSelectedRef.current) checkoutData.is_service_selected = true;
        if (siteContext.channel === 'dine_in') {
            checkoutData.is_service_selected = serviceSelectedRef.current ?? false;
        }
        checkoutData.gratuity = gratuityVal;

        return checkoutData;
    };

    const processOrder = async (finalOrder: OrderData, stripe?: Stripe) => {

        iLog('begin processOrder', finalOrder);
        setLoading(true);

        await postOrder(finalOrder)
            .then((data) => {
                // ALL GOOD - GO TO CONFIRMATION PAGE
                iLog('order successful!', data);

                //data.basket = basketContext.validatedBasket;
                data.site = basketContext.validatedSite;
                data.orderData = finalOrder;
                data.ready_time = basketContext.validatedBasket?.ready_time;

                setOrder(data);
                setStoredOrder(data);

                basketContext.clearBasket();

                mixpanel.register({
                    'name': data.customer_name,
                    'email': data.customer_email,
                    'ref': data.ref
                });

                navigate('/confirmation/' + data.ref);

            })
            .catch((error) => {
                if (error.code === APIErrorCode.Stripe3DSRequired) {
                    iLog('catch postOrder', error);
                    // alert('timeslot error');
                    if (error.extra_data.requires_action) {
                        // STRIPE SCA ACTION REQUIRED
                        // Use Stripe.js to handle required card action
                        // stripe may not exist for 100% discounted orders..
                        if (stripe) {
                            stripe
                                .handleCardAction(error.extra_data.payment_intent_client_secret)
                                .then(function (result: any) {
                                    if (result.error) {

                                        setLoading(false);
                                        toastError(result.error.message ?? t`Sorry, there has been an unexpected error. Please try again.`);

                                    } else {
                                        //redo checkout
                                        finalOrder.payment_intent = JSON.stringify({
                                            payment_intent: result.paymentIntent.client_secret,
                                        }) as any;
                                        finalOrder.is_secure_payment = true;
                                        processOrder(finalOrder, stripe);
                                    }
                                });
                        }
                    }
                } else {
                    throw error;
                }
            })
            .finally(() => setLoading(false));
        // .catch((error) => {
        //     console.log('fail????', error)
        // })
    };

    const signUpCustomer = () => {
        if ( order ) {
            return postCustomer(order.ref, basketContext.basket?.flow ?? 'no-flow');
        }
    };

    let orderContextValue: OrderContextData = {
        order,
        setOrder,

        storedOrder,
        setStoredOrder,

        orderData,
        setOrderData,

        getOrderData,
        processOrder,
        signUpCustomer,

        loading,
        setLoading,

        // timeslot,
        // setTimeslot,

        serviceSelected,
        setServiceSelected,

        gratuitySelected,
        setGratuitySelected,
        gratuityVal,
        setGratuityVal,

        deliveryInstructions,
        setDeliveryInstructions,

        total,
    };
    return <OrderContext.Provider value={orderContextValue}>{children}</OrderContext.Provider>;
};

export default OrderContextProvider;
